import {
	type ErrorResponse,
	isRouteErrorResponse,
	useParams,
	useRouteError,
} from '@remix-run/react'
import LogRocket from 'logrocket'
import { getErrorMessage } from '#app/utils/misc.tsx'
import { Icon } from './ui/icon.tsx'

type StatusHandler = (info: {
	error: ErrorResponse
	params: Record<string, string | undefined>
}) => JSX.Element | null

export function GeneralErrorBoundary({
	defaultStatusHandler = ({ error }) => (
		<p>
			{error.status} {error.data}
		</p>
	),
	statusHandlers,
	unexpectedErrorHandler = error => <p>{getErrorMessage(error)}</p>,
}: {
	defaultStatusHandler?: StatusHandler
	statusHandlers?: Record<number, StatusHandler>
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}) {
	const error = useRouteError()
	const params = useParams()

	if (typeof document !== 'undefined') {
		console.error(error)

		if (ENV.MODE === 'production') {
			LogRocket.captureException(error as Error)
		}
	}

	return (
		<div className="container flex flex-col items-center justify-center gap-2 p-20">
			<div className="flex flex-col gap-2 rounded-lg border-[1px] border-input bg-slate-50 p-8 dark:bg-slate-800">
				<Icon size="hero" name="jazz-logo" />
				<p className="text-large">Looks like there was an error.</p>
				<div className="text-small">
					{isRouteErrorResponse(error)
						? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
								error,
								params,
						  })
						: unexpectedErrorHandler(error)}
				</div>
			</div>
		</div>
	)
}
